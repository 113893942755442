import React from "react"
import { Router } from "@reach/router"
import { Main, PrivateRoute } from "../components"

const App = () => (
  <Router>
    <PrivateRoute path="/" component={Main} />
  </Router>
)

export default App
